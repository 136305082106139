import { login, logout, getInfo, codeLogin } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    // Token
    token: getToken(),
    // 名称
    name: '',
    // 头像
    avatar: '',
    // 用户ID
    userId: 0,
    userMoney: {},
    // 印章
    seal: {},
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
  SET_USER_MONEY: (state, userMoney) => {
    state.userMoney = userMoney
  },
  SET_SEAL: (state, seal) => {
    state.seal = seal
  }
}

const actions = {
  /**
   * 扫码登录
   * @param {*} param0 
   * @param {*} params 
   */
  codeLogin({ commit }, params) {
    const { loginKey } = params
    return new Promise((resolve, reject) => {
      // console.log(2222, loginKey);
      codeLogin({ loginKey }).then(response => {
        const { data } = response
        if (!data.timeout && data.token) {
          // console.log(1111111, data);
          commit('SET_TOKEN', data.token)
          commit('SET_NAME', data.user.nickName)
          commit('SET_AVATAR', data.user.avatarUrl)
          commit('SET_USER_ID', data.user.id)
          setToken(data.token)
        }
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response
        console.log(data);
        if (!data) {
          return reject('Verification failed, please Login again.')
        }

        const { nickName, avatarUrl, id, userMoney, Seal } = data;

        commit('SET_NAME', nickName);
        commit('SET_AVATAR', avatarUrl);
        commit('SET_USER_ID', id);
        commit('SET_USER_MONEY', userMoney);
        commit('SET_SEAL', Seal);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        removeToken() // must remove  token  first
        resetRouter()
        commit('RESET_STATE')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

