<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <h1 v-else class="sidebar-title">{{ title }} </h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <h1 class="sidebar-title">{{ title }} </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: 'XHOME',
      logo: require("@/assets/images/logo.png"),
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #0091FF;
  // text-align: center;
  // overflow: hidden;

  & .sidebar-logo-link {

    & .sidebar-logo {
      float: left;
      // width: 32px;
      // margin-top: 15px;
      // margin-left: 10px;
      width: 24px;
      margin-top: 12px;
      margin-left: 15px;
    }

    & .sidebar-title {
      display: inline-block;
      margin-left: 10px;
      color: #fff;
      font-weight: 600;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }

    // height: 100%;
    // width: 100%;

    // & .sidebar-logo {
    //   width: 32px;
    //   // height: 32px;
    //   vertical-align: middle;
    //   margin-right: 12px;
    // }

    // & .sidebar-title {
    //   display: inline-block;
    //   margin: 0;
    //   color: #fff;
    //   font-weight: 600;
    //   line-height: 50px;
    //   font-size: 14px;
    //   font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    //   vertical-align: middle;
    // }
  }

  // &.collapse {
  //   .sidebar-logo {
  //     margin-right: 0px;
  //   }
  // }
}
</style>
