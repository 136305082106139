import Vue from 'vue';
import VueRouter from 'vue-router';

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter);

import Layout from '@/views/layout';

const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/about',
    component: () => import('@/views/login/AboutUs'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index'),
      meta: {
        title: '首页',
        icon: 'iconfont iconzhuye'
      }
    }]
  },

  {
    path: '/template',
    component: Layout,
    redirect: '/template',
    children: [
      {
        path: 'list',
        name: 'Template',
        component: () => import('@/views/template/index'),
        meta: {
          title: '签字文件模板',
          icon: 'iconfont iconhetongmoban'
        }
      },
      {
        path: 'edit',
        component: () => import('@/views/template/EditTemplate'),
        hidden: true
      },
      {
        path: 'create',
        component: () => import('@/views/template/CreatedByTpl'),
        hidden: true
      },
    ]
  },

  {
    path: '/signature',
    component: Layout,
    redirect: '/signature/draft',
    name: 'Signature',
    meta: { title: '签字文件管理', icon: 'iconfont iconwenjian' },
    children: [
      {
        path: 'draft',
        name: 'draft',
        component: () => import('@/views/signature/Draft'),
        meta: { title: '草稿箱' }
      },
      {
        path: 'issued',
        name: 'issued',
        component: () => import('@/views/signature/Issued'),
        meta: { title: '我发出的' }
      },
      {
        path: 'undone',
        name: 'undone',
        component: () => import('@/views/signature/Undone'),
        meta: { title: '未完成' }
      },
      {
        path: 'completed',
        name: 'completed',
        component: () => import('@/views/signature/Completed'),
        meta: { title: '已完成' }
      }
    ]
  },

  {
    path: '/account',
    component: Layout,
    redirect: '/account',
    children: [{
      path: 'account',
      name: 'Account',
      component: () => import('@/views/account/index'),
      meta: {
        title: '我的账户',
        icon: 'iconfont iconwodezhanghu'
      }
    }]
  },

  {
    path: 'xhome',
    component: Layout,
    children: [
      {
        path: 'http://b.xhome.net',
        meta: { title: '阿姨通系统', icon: 'el-icon-position' }
      }
    ]
  },

  { path: '*', redirect: '/404', hidden: true }
]


const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
});

/**
 * reset router
 */
export function resetRouter() {
  const newRouter = router;
  router.matcher = newRouter.matcher;
}

export default router
