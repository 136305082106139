import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/vue-admin-template/user/login',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/user/getUserAccount',
    method: 'post',
    data: { token }
  })
}

export function logout() {
  return Promise.resolve({
    code: 200,
    data: 'success'
  });
  // return request({
  //   url: '/vue-admin-template/user/logout',
  //   method: 'post'
  // })
}

/**
 * 扫码登录
 * @param {*} data 
 */
export function codeLogin(data) {
  return request({
    url: '/user/codeLogin',
    method: 'post',
    data
  })
}