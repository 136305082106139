import request from '@/utils/request'

/**
 * 获取签字列表
 * Added by Jason.Song on 2020/11/17 16:37:10
 */
export function getList(data) {
  return request({
    url: '/sign/getSignFileOrder',
    method: 'post',
    data
  })
}

/**
 * 生成并下载PDF文件
 * Added by Jason.Song on 2020/11/17 16:37:04
 */
export function createPDF(data) {
  return request({
    url: '/sign/createSignFilePDF',
    method: 'post',
    data
  })
}

/**
 * 重命名文件
 * Added by Jason.Song on 2020/11/17 16:40:20
 */
export function renameFile(data) {
  return request({
    url: '/sign/renameOnlineFile',
    method: 'post',
    data
  })
}

/**
 * 复制签名文件
 * Added by Jason.Song on 2020/11/17 16:41:19
 */
export function copyFile(data) {
  return request({
    url: '/sign/copySignFile',
    method: 'post',
    data
  })
}

/**
 * 删除签名文件
 * Added by Jason.Song on 2020/11/17 16:42:42
 */
export function deleteFile(data) {
  return request({
    url: '/sign/deleteSignFile',
    method: 'post',
    data
  })
}

/**
 * 获取购买次数套餐或开通会员列表
 * Added by Jason.Song on 2020/11/25 11:53:39
 */
export function getRechargeData() {
  return request({
    url: '/sign/getRechargeData',
    method: 'post'
  })
}

/**
 * 创建远程签字套餐
 * Added by Jason.Song on 2020/11/25 19:28:45
 */
export function createOrder(data) {
  return request({
    url: '/createOrder',
    method: 'post',
    data
  })
}

/**
 * 构建支付相关信息
 * Added by Jason.Song on 2020/11/25 19:35:31
 */
export function buildPayment(data) {
  return request({
    url: '/tenpay/buildPayment',
    method: 'post',
    data
  })
}

/**
 * 校验支付结果
 * Added by Jason.Song on 2020/11/26 10:26:42
 */
export function checkPayStatus(data) {
  return request({
    url: '/tenpay/checkPayStatus',
    method: 'post',
    data
  })
}

/**
 * 保存上传文件至草稿箱
 * Added by Jason.Song on 2020/11/26 13:29:10
 */
export function createOnlineFile(data) {
  return request({
    url: '/sign/createOnlineFile',
    method: 'post',
    data
  })
}

/**
 * 签名文件统计数据
 * Added by Jason.Song on 2020/11/27 14:55:07
 */
export function signFileCount() {
  return request({
    url: '/sign/signFileCount',
    method: 'post'
  })
}

/**
 * 关闭签署文件
 * Added by Jason.Song on 2020/11/27 16:23:29
 */
export function closeSignFileOrder(data) {
  return request({
    url: '/sign/closeSignFileOrder',
    method: 'post',
    data
  })
}

/**
 * 创建小程序码
 * Added by Jason.Song on 2020/12/04 17:15:05
 */
export function createWxCode(data) {
  return request({
    url: '/createWxCode',
    method: 'post',
    data
  })
}

/**
 * 将Word文档转成Form表单和图片
 * Added by Jason.Song on 2020/12/08 13:59:03
 */
export function word2form(data) {
  return request({
    url: '/sign/word2form',
    method: 'post',
    data
  })
}

/**
 * 获取用户签名位置
 * Added by Jason.Song on 2020/12/09 14:32:04
 */
export function getUserSignPosition(data) {
  return request({
    url: '/sign/getUserSignPosition',
    method: 'post',
    data
  })
}

/**
 * 保存模板
 * Added by Jason.Song on 2020/12/11 16:17:20
 */
export function saveTemplate(data) {
  return request({
    url: '/sign/saveTemplate',
    method: 'post',
    data
  })
}

/**
 * 获取模板列表
 * Added by Jason.Song on 2020/12/11 18:16:54
 */
export function getTemplateList(data) {
  return request({
    url: '/sign/getTemplateList',
    method: 'post',
    data
  })
}

/**
 * 获取模板详情
 * Added by Jason.Song on 2020/12/14 11:17:23
 */
export function getTemplate(data) {
  return request({
    url: '/sign/getTemplate',
    method: 'post',
    data
  })
}

/**
 * 删除模板
 * Added by Jason.Song on 2020/12/14 10:52:19
 */
export function deleteTemplate(data) {
  return request({
    url: '/sign/deleteTemplate',
    method: 'post',
    data
  })
}

/**
 * 通过模板创建签字文件
 * Added by Jason.Song on 2020/12/15 16:48:31
 */
export function ssFromTemplate(data) {
  return request({
    url: '/sign/ssFromTemplate',
    method: 'post',
    data
  })
}
